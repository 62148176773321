<template>
  <v-container>
    <div v-show="isVisibleProp"></div>
      <v-row>
        <v-col cols="3">
          <v-text-field id="miscEditDialogFyText"
            class="misc-edit-dialog-fy-text"
            label="Fiscal Year"
            v-model.number="editedItem.fiscalYear"
            type="number"
            @change="loadLists"
            :rules="fiscalYearValidation"
            @blur="determineReceiptNo(), determineTransactionNo()"
            :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <ClarionDateControl id="miscEditDialogBuisnessDate"
          class="misc-edit-dialog-buisness-date"
          :isIsoDate="true"
          label="Business Date"
          v-model="editedItem.businessDate"
          :rules="registerDateRules"
          />
        </v-col>
        <v-col cols="3">
          <GetMaxReceiptNumber id="miscEditDialogReceiptNumber"
            class="misc-edit-dialog-receipt-number"
            v-model.number="editedItem.receiptNumber"
            :fiscalYear="editedItem.fiscalYear"
            :isNewItem="isNewItem"
            :rules="reqdSelectValidation"
            fieldType="Receipt Number"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field id="miscEditDialogWorkStationSelect"
            class="misc-editDialog-work-station-select"
            label="Work Station"
            :value="editedItem.wkst"
            type="string"
            :disabled="true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field id="miscEditDialogReceiptAmount"
            class="misc-edit-dialog-receipt-amount"
            label="Receipt Amount"
            v-model="editedItem.amount"
            :rules="amountValidation"
            @blur="roundAmount"
            @focus="amountToNum(), $event.target.select()"
            autofocus
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete id="miscEditDialogReceivedOf"
            class="misc-trans-fund"
            label="Received Of"
            v-model="editedItem.whomStr"
            :items="whoms"
            @keydown="whomShortcutHandler"
            @update:list-index="whomUpdateSearchInputByNumber"
            @update:search-input="whomUpdateSearchInput"
            @focus="onFocusAutocomplete"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-select id="miscEditDialogBankNameSelect"
            class="misc-editDialog-bank-name-select"
            label="Bank Alt Key"
            :items="banks"
            item-text="bankAltKey"
            item-value="_id"
            v-model="editedItem.bank"
            :rules="isBankAltkeyRequired"
          ></v-select>
        </v-col>
        <v-col cols="3" v-if="editedItem.isApportionLikeTax">
          <v-text-field id="miscEditDialogTaxYearSelect"
            class="misc-editDialog-tax-year-select"
            label="Tax Year"
            :rules="reqdSelectValidation"
            v-model.number="editedItem.taxYear"
            type="number"
            :disabled="true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-textarea id="miscEditDialogDescriptionText"
            class="misc-edit-dialog-description-text"
            label="Description"
            rows="2"
            v-model="editedItem.description"
          ></v-textarea>
        </v-col>
        <v-col cols="2">
          <v-checkbox id="miscEditDialogVoidCheckBox"
            class="misc-edit-dialog-void-checkbox"
            v-model="editedItem.isVoid"
            label="Void"
            @change="isVoidChecked"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
        <v-checkbox id="miscEditDialogsApprCheckBox"
            class="misc-edit-dialog-spl-appr-checkbox"
            v-model="editedItem.isSpecialApportionment"
            @click="showSpecialApportionmentBtn"
            label="Special Apportionment"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
        <v-checkbox id="miscEditDialogsApprLikeTaxCheckBox"
            class="misc-edit-dialog-appr-like-tax-checkbox"
            v-model="editedItem.isApportionLikeTax"
            v-if = "editedItem.isApportionLikeTax || isNewItem"
            @click="showTaxSummaryReceiptBtn"
            label="Apportion Like Taxes"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
        <GetMaxTransNumber id="mtgTransItemTransNoText"
          v-model.number="editedItem.transactionNumber"
          class="mtg-trans-item-trans-no-Text"
          fieldType="Transaction Number"
          :rules="reqdSelectValidation"
          :fiscalYear="editedItem.fiscalYear"
          :cmnBaseCrudKey="baseCrudKey"
          :loadLastTransactionNo="loadLastTransactionNo"
        />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <MiscDetails id="miscEditDialogMiscDetails"
            class="misc-edit-dialog-misc-details"
            v-if="forceReloadDetails"
            v-model="editedItem.details"
            :baseCrudKey="baseCrudKey"
            :amount="editedItem.amount"
            :transItem="editedItem"
            :funds="funds"
            :sources="sources"
            :unitCodes="unitCodes"
            :taxDistricts="taxDistricts"
            @totalRemainingReconAmount="totalRemainingReconAmount"
            @setCalculatePercentage="setCalculatePercentage"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <PaymentDetails id="miscEditDialogPaymentDetails"
            class="misc-edit-dialog-payment-details"
            v-model="editedItem.paymentDetails"
            toolbarTitle="Payment Details"
            :amount="editedItem.amount"
            :cmnEnums="cmnEnums"
            :paymentTypeFlags="isPaymentDetailsRequiredDefaultPaymentType"
            :defaultPaymentType="miscSettingItem"
            :defaultForteConfig="defaultForteConfig"
            :isDialogBoxVisible="isVisibleProp"
            :isTotal="true"
            @forteResponse="miscForteResponse"
            @totalRemainingPaymentAmount="totalRemainingPaymentAmount"
          />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import MiscDetails from './MiscDetails.vue';
import PaymentDetails from '../../common/paymentDetails/PaymentDetails.vue';
import GetMaxReceiptNumber from './GetMaxReceiptNumber.vue';
import {
  amountFormat,
  onFocusAutocomplete,
  amountToNumber,
  checkStringEmptiness,
} from '../../../util/shared/vue-global';
import { defaultDurationOfCorrection, isNotBeforeDate } from '../../../util/shared/tmc-global';
import MiscTransactionListMixin from './MiscTransactionList.mixin';
import GetMaxTransNumber from '../../common/transactionNumber/GetMaxTransNumber.vue';

const bankCrudKey = 'sharedBank';

export default {
  name: 'MiscTransactionEditedItem',
  mixins: [MiscTransactionListMixin],
  components: {
    ClarionDateControl,
    MiscDetails,
    GetMaxReceiptNumber,
    PaymentDetails,
    GetMaxTransNumber,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'miscTrans-dialog',
    },
    attachmentConstants: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    whomLastSearched: undefined,
    totalReconAmount: null,
    totalRemainingPayAmt: null,
    funds: [],
    sources: [],
    unitCodes: [],
    forceReloadDetails: true,
    whomListCrudKey: 'miscWhom',
    taxDistrictCrudKey: 'sharedTaxDistrict',
    defaultForteConfig: {
      configScope: 'miscConfig',
      isRequiredForte: true,
    },
  }),
  watch: {
    editedItem: {
      handler(val) {
        this.$emit('editItem', val);
      },
      deep: true,
    },
  },
  created() {
    this.determineReceiptNo();
    this.determineTransactionNo();
    this.loadLists();
    this.loadBanks({
      criteria: {
        notEmptyAltKey: true,
        limit: 0,
      },
      baseCrudKey: bankCrudKey,
    });
    this.loadConfig({ requiredScope: 'miscConfig' });
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapGetters([
      'todaysDate',
    ]),
    ...mapState({
      nextReceiptNumber: (state) => state.misc.miscTransaction.nextReceiptNumber,
      cmnEnums: (state) => ({
        cmnPaymentTypes: state.enums.MISCPaymentTypeEnum || [],
        cmnEFTTypes: state.enums.MISCEFTTypeEnum || [],
        cmnCardTypes: state.enums.MISCCardTypeEnum || [],
      }),
      miscSettingItem: (state) => state.SystemConfig.miscSettingItem || {},
      nextTransNumber: (state) => state.common.cmnTransNumber.nextTransNumber,
      transNoPulse: (state) => state.common.cmnTransNumber.transNoPulse,
    }),
    isPaymentDetailsRequiredDefaultPaymentType() {
      return {
        eftPaymentType: this.isNewItem && this.miscSettingItem.eftPaymentType,
        ccPaymentType: this.isNewItem && this.miscSettingItem.ccPaymentType,
      };
    },
    isBankAltkeyRequired() {
      if (this.miscSettingItem && this.miscSettingItem.requireBankAltKey) {
        return [(value) => !!value || 'Required'];
      }
      return [];
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    whoms() {
      const miscWhomsList = (this.items(this.whomListCrudKey) || []).map(
        (r) => r.description || '',
      );
      miscWhomsList.push(this.editedItem.whomStr || '');
      return miscWhomsList;
    },
    banks() {
      const bankObjs = this.items(bankCrudKey) || [];
      const updatedBankObjs = bankObjs.map((b) => ({
        ...b,
        bankAltKey: ` ${b.altKeyBase || ''} - ${b.bankName}`,
      }));
      return [{ _id: null, bankName: '', bankAltKey: '' }, ...updatedBankObjs];
    },
    taxDistricts() {
      const taxDistrictObjs = this.items(this.taxDistrictCrudKey) || [];
      const updatedTaxDistObj = taxDistrictObjs.map((t) => ({
        ...t,
        taxDistrictDesc: `${t.districtCode} ${t.districtName || ''}`,
      }));
      return [{ _id: null, districtCode: '', taxDistrictDesc: '' }, ...updatedTaxDistObj];
    },
    amountValidation() {
      return [
        (this.editedItem.isCalculatePercentage || this.totalReconAmount === 0) || ('Mismatched Reconciliation Amount'),
        (this.isPaymentAmtMatched) || ('Mismatched Payment Amount'),
      ];
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    isVisibleProp() {
      const isVisible = this.isVisible(this.baseCrudKey);
      if (isVisible) {
        this.loadLists();
        this.showTaxSummaryReceiptBtn();
        this.showSpecialApportionmentBtn();
        if (this.editedItem._id) {
          this.loadFiles();
        }
        if (this.isNewItem) {
          this.determineReceiptNo();
          this.determineTransactionNo();
        }
      } else {
        this.setReceiptPolling(undefined);
        this.setTransNoPolling(undefined);
      }
      return isVisible;
    },
    isPaymentAmtMatched() {
      return (this.editedItem.paymentDetails || []).length === 0 || this.totalRemainingPayAmt === 0;
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    durationOfCorrection() {
      if (this && this.miscSettingItem && this.miscSettingItem.durationOfCorrection) {
        return parseInt(this.miscSettingItem.durationOfCorrection, 10)
          || defaultDurationOfCorrection;
      }
      return defaultDurationOfCorrection;
    },
    registerDateRules() {
      return [
        (value) => {
          const isNotBefore = isNotBeforeDate(
            value, this.todaysDate, (this.durationOfCorrection + 1),
          );
          return isNotBefore || 'You are not allowed to change or enter transactions that'
            + ` are more than ${this.durationOfCorrection} business days old. Please use a correction`
            + ' transaction type for today\'s date instead.';
        },
        (value) => !!value || 'Required',
      ];
    },
    fiscalYearValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => !Number.isNaN(parseFloat(value)) || 'Numeric required',
        (value) => parseFloat(value) > 0 || 'Must be a positive year',
        (value) => (value || '').toString().length === 4 || 'Fiscal year must be 4 digits',
        (value) => parseFloat(value) < 3000 || 'Must be a reasonable year',
      ];
    },
    fiscalYearLength() {
      return (this.editedItem.fiscalYear || '').toString().length;
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    ...mapActions('files', [
      'loadAttachedFiles',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    ...mapActions('common/cmnWhom', [
      'loadCmnWhom',
      'upsrtCmnWhom',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    ...mapActions('shared/source', [
      'loadCmnSource',
    ]),
    ...mapActions('misc/schoolUnit', [
      'loadMiscUnitCode',
    ]),
    ...mapActions('misc/miscTransaction', [
      'loadLastReceiptNo',
      'loadLastTransactionNo',
    ]),
    ...mapActions([
      'flashError',
      'flashInfo',
    ]),
    ...mapMutations('misc/miscTransaction', [
      'setReceiptPolling',
      'setReceiptPulse',
    ]),
    ...mapMutations('common/cmnTransNumber', [
      'setTransNoPolling',
      'setTransNoPulse',
    ]),
    amountFormat,
    onFocusAutocomplete,
    amountToNumber,
    registerDateBlurred(value) {
      const isNotBeforeTwoPrior = isNotBeforeDate(
        value, this.todaysDate, (this.durationOfCorrection + 1),
      );
      const valueDt = new Date(value);
      const todayDt = new Date(this.todaysDate);
      const diffMs = todayDt - valueDt;
      if (isNotBeforeTwoPrior && diffMs > 0) {
        const errMsg = `Please remember to export data for the GL for ${value}
        as this change will affect GL balances for ${value}.`;
        this.flashInfo(errMsg);
      }
    },
    whomUpdateSearchInput(val) {
      this.whomLastSearched = val;
    },
    whomUpdateSearchInputByNumber(number) {
      this.whomLastSearched = this.whoms[number];
    },
    async whomShortcutHandler(e) {
      if (e.key === 'Tab') {
        const { whomStr } = this.editedItem;
        const { whomLastSearched } = this;
        const fields = {
          _id: '',
          description: '',
          fiscalYear: '',
        };
        const matchWhoms = this.whoms.filter((w) => w === whomStr);
        const matchWhomLastSearched = this.whoms.filter(
          (w) => w === whomLastSearched,
        );
        if (matchWhoms.length > 0 || whomLastSearched) {
          const matchWhom = matchWhoms[0] ? matchWhoms[0] : '';
          if (
            (matchWhom || '').toLowerCase()
              !== (whomLastSearched || '').toLowerCase()
            && matchWhomLastSearched.length === 0
          ) {
            this.flashInfo('Creating a new Whom entry.');
            // create a new record to match the description typed
            const item = {
              description: whomLastSearched,
              fiscalYear: this.editedItem.fiscalYear,
            };
            await this.upsrtCmnWhom({
              item,
              fields,
              baseCrudKey: this.whomListCrudKey,
            });
            await this.loadCmnWhom({
              criteria: {
                fiscalYear: this.editedItem.fiscalYear,
              },
              fields,
              baseCrudKey: this.whomListCrudKey,
            });
          }
          this.editedItem.whomStr = whomLastSearched;
        }
        this.handlingTabOrder(e);
      }
    },
    totalRemainingPaymentAmount(value) {
      this.totalRemainingPayAmt = amountToNumber(value);
      return value;
    },
    totalRemainingReconAmount(value) {
      this.totalReconAmount = amountToNumber(value);
      return value;
    },
    roundAmount() {
      this.editedItem.amount = amountFormat(amountToNumber(this.editedItem.amount));
    },
    amountToNum() {
      this.editedItem.amount = amountToNumber(this.editedItem.amount);
    },
    showTaxSummaryReceiptBtn() {
      this.$emit('showOnClickApportionLikeTax', this.editedItem.isApportionLikeTax);
    },
    showSpecialApportionmentBtn() {
      this.$emit('showOnClickSpecialApportionment', this.editedItem.isSpecialApportionment);
    },
    loadLists() {
      if (this.fiscalYearLength === 4) {
        this.loadFundList();
        this.loadSourceList();
        this.loadUnitCodeList();
        this.loadCmnWhomList();
      }
    },
    async loadFundList() {
      const fundObjs = await this.loadFunds({
        criteria: {
          fiscalYear: this.editedItem.fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
      });
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      this.funds = [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    async loadSourceList() {
      const sourceObjs = await this.loadCmnSource({
        criteria: {
          fiscalYear: this.editedItem.fiscalYear,
          isSkipMiscellaneousTransaction: false,
          sortBy: [
            {
              column: 'sourceSubSourceNumber',
              direction: 'asc',
            },
          ],
        },
      });
      const updatedSourceObj = sourceObjs.map((s) => {
        const firstPartDesc = `${s.sourceSubSourceNumber} ${s.sourceDescription || ''}`;
        const secondPartDesc = s.subSourceDescription;
        return {
          ...s,
          sourceDesc: secondPartDesc == null || '' ? `${s.sourceSubSourceNumber} ${s.sourceDescription}` : `${firstPartDesc} - ${secondPartDesc}`,
        };
      });
      this.sources = [{ _id: null, sourceSubSourceNumber: '', sourceDesc: '' }, ...updatedSourceObj];
    },
    async loadUnitCodeList() {
      const unitCodeObjs = await this.loadMiscUnitCode({
        criteria: {
          fiscalYear: this.editedItem.fiscalYear,
          sortBy: [
            {
              column: 'unitCode',
              direction: 'asc',
            },
          ],
        },
      });
      const updatedUnitObj = unitCodeObjs.map((u) => ({
        ...u,
        unitDesc: `${u.unitCodeNumber} ${u.description || ''}`,
      }));
      this.unitCodes = [{ _id: null, unitCodeNumber: '', unitDesc: '' }, ...updatedUnitObj];
    },
    async loadCmnWhomList() {
      await this.loadCmnWhom({
        criteria: {
          fiscalYear: this.editedItem.fiscalYear,
        },
        baseCrudKey: 'miscWhom',
      });
    },
    determineReceiptNo() {
      if (this.isNewItem) {
        if (this.fiscalYearLength !== 4) {
          this.setReceiptPolling(undefined);
        } else {
          const miscTransPolling = setInterval(
            this.getRecNo,
            1200,
          );
          this.setReceiptPolling(miscTransPolling);
        }
      }
    },
    getRecNo() {
      const { fiscalYear } = this.editedItem;
      if (!(checkStringEmptiness(fiscalYear)) && this.isNewItem) {
        this.determineNextReceiptNo(
          {
            fiscalYear,
          },
        );
      }
    },
    determineNextReceiptNo({ fiscalYear }) {
      const loadReceiptNoPromise = this.loadLastReceiptNo(
        { fiscalYear },
      );
      loadReceiptNoPromise.then(() => {
        let animate = false;
        if (this.isNewItem) {
          animate = true;
        }
        this.editedItem.receiptNumber = this.nextReceiptNumber;
        if (animate) {
          this.$nextTick(() => {
            this.setReceiptPulse(true);
          });
        } else {
          this.setReceiptPulse(false);
        }
      });
    },
    setCalculatePercentage(value) {
      this.forceReloadDetails = false;
      this.editedItem.isCalculatePercentage = value;
      this.$nextTick(() => {
        this.forceReloadDetails = true;
      });
    },
    isVoidChecked() {
      if (this.editedItem.isVoid) {
        this.editedItem.amount = 0.00;
        this.editedItem.details = (this.editedItem.details || []).map((item) => {
          const updatedItem = { ...item };
          updatedItem.amount = 0.00;
          return updatedItem;
        });
      }
    },
    loadFiles() {
      this.loadAttachedFiles({
        bucketName: this.attachmentConstants.bucketName,
        program: this.attachmentConstants.program,
        parentObjectType: this.attachmentConstants.parentObjectType,
        parentObjectId: this.editedItem._id,
      });
    },
    miscForteResponse(res) {
      this.editedItem.description += res;
    },
    determineTransactionNo() {
      if (this.isNewItem) {
        if (this.fiscalYearLength !== 4) {
          this.setTransNoPolling(undefined);
        } else {
          const transNoPolling = setInterval(
            this.getTransNo,
            1200,
          );
          this.setTransNoPolling(transNoPolling);
        }
      }
    },
    getTransNo() {
      const { fiscalYear } = this.editedItem;
      if (!(checkStringEmptiness(fiscalYear)) && this.isNewItem) {
        this.determineNextTransNo(
          {
            fiscalYear,
          },
        );
      }
    },
    determineNextTransNo({ fiscalYear }) {
      const loadTransNoPromise = this.loadLastTransactionNo(
        { fiscalYear },
      );
      loadTransNoPromise.then(() => {
        const animate = true;
        this.editedItem.transactionNumber = this.nextTransNumber;
        if (animate) {
          this.$nextTick(() => {
            this.setTransNoPulse(true);
          });
        } else {
          this.setTransNoPulse(false);
        }
      });
    },
    handlingTabOrder(e) {
      if (this.editedItem && this.editedItem.whomStr) {
        e.preventDefault();
        document.getElementById('miscEditDialogBankNameSelect').focus();
      }
    },
  },
};
</script>
