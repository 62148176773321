<template>
  <div>
    <v-container class="grey lighten-5 mb-6">
      <h3>Reconciliation Details</h3>
      <v-data-table
        dense
        :headers="headers"
        :items-per-page="-1"
        :items="editedItem"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <div class="d-flex justify-end">
            <v-checkbox
              class="recon-details-calculate-percentage-flag"
              v-model="calculatePercentage"
              label="Use % Breakdown"
            ></v-checkbox>
            <v-btn
              class="mx-2 recon-details-add-new-item"
              color="primary" fab icon x-small
              @click="addOne"
              v-if="amountToNumber(amountRemaining) > 0"
            >
              <v-icon>
                {{icons.mdiPlus}}
              </v-icon>
          </v-btn>
          </div>
        </template>

        <template v-slot:[`item.taxDistrict`]="{ item }">
          <v-select
            v-model="item.taxDistrict"
            :items="taxDistricts"
            v-if="transItem.isApportionLikeTax"
            item-text="taxDistrictDesc"
            item-value="_id"
            dense
            single-line
            @blur="somethingChanged"
            :rules="reqdSelectValidation"
          ></v-select>
        </template>

        <template v-slot:[`item.fund`]="{ item }">
          <v-autocomplete
            v-model="item.fund"
            v-if="!transItem.isApportionLikeTax"
            label="Fund"
            item-text="fundDesc"
            item-value="_id"
            :items="funds"
            dense
            single-line
            @blur="somethingChanged"
            :rules="reqdSelectValidation"
          ></v-autocomplete>
        </template>

        <template v-slot:[`item.source`]="{ item }">
          <v-autocomplete
            v-model="item.source"
            label="Fund"
            item-text="sourceDesc"
            item-value="_id"
            :items="sources"
            dense
            single-line
            @blur="somethingChanged"
          ></v-autocomplete>
        </template>

        <template v-slot:[`item.unit`]="{ item }">
            <v-autocomplete
            label="School/Unit Code"
            v-model="item.unit"
            v-if="!transItem.isApportionLikeTax"
            item-text="unitDesc"
            item-value="_id"
            :items="unitCodes"
            :disabled="isRequiredUnitChecked(item)"
            dense
            single-line
            @blur="somethingChanged"
          ></v-autocomplete>
        </template>

        <template v-slot:[`item.percentageBreakdown`]="{ item }">
          <v-text-field
            dense
            single-line
            label="Percentage Breakdown"
            v-model="item.percentageBreakdown"
            v-if="calculatePercentage"
            @blur="somethingChanged(), calculateAmount()"
          ></v-text-field>
        </template>

        <template v-slot:[`item.amount`]="{ item, index }">
          <v-text-field
            dense
            single-line
            label="Amount"
            v-model="item.amount"
            @blur="somethingChanged"
            @keydown="shortcutHandler($event, index);"
            @keydown.enter="somethingChanged"
          ></v-text-field>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small class="mr-2"
            tabindex="-1"
            @click="removeOne(item)"
          >
            {{icons.mdiDelete}}
          </v-icon>
        </template>
        <template slot="body.append">
          <tr class="blue--text">
              <td id="total-trans-amount">
                Total Reconciliation Amount: ${{ amountFormat(amountRemaining) }}
              </td>
              <td id="total-percentage"
                v-if="calculatePercentage">
                Total Remaining Percentage: {{amountFormat(percentageRemaining)}}%
              </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import {
  amountFormat,
  amountToNumber,
  extractId,
  isTabOrPlus,
} from '../../../util/shared/vue-global';

const defaultItem = {
  fund: null,
  taxDistrict: null,
  source: null,
  unit: null,
  amount: 0,
};

export default {
  name: 'MiscDetails',
  created() {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'miscTrans-dialog',
    },
    value: {
      type: Array,
      default: () => [],
    },
    funds: {
      type: Array,
      default: () => [],
    },
    sources: {
      type: Array,
      default: () => [],
    },
    unitCodes: {
      type: Array,
      default: () => [],
    },
    transItem: {
      type: Object,
      default: () => {},
    },
    taxDistricts: {
      type: Array,
      default: () => [],
    },
    amount: [Number, String],
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    defaultHeaders: [
      { text: 'Source', value: 'source' },
      { text: 'Fund', value: 'fund' },
      { text: 'Tax District', value: 'taxDistrict' },
      { text: 'Unit', value: 'unit' },
      { text: 'Percentage Breakdown', value: 'percentageBreakdown' },
      { text: 'Amount', value: 'amount' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  watch: {
    amount() {
      if (this.calculatePercentage) {
        this.calculateAmount();
        this.somethingChanged();
      }
    },
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    headers() {
      let updatedHeaders = [...this.defaultHeaders];
      if (!this.transItem.isApportionLikeTax) {
        updatedHeaders = updatedHeaders.filter((h) => h.value !== 'taxDistrict');
      }
      if (this.transItem.isCalculatePercentage === false) {
        updatedHeaders = updatedHeaders.filter((h) => h.value !== 'percentageBreakdown');
      }
      if (this.transItem.isApportionLikeTax) {
        updatedHeaders = updatedHeaders.filter((h) => h.value !== 'fund' && h.value !== 'unit');
      }
      return updatedHeaders;
    },
    editedItem: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    reqdSelectValidation() {
      return [(value) => !!value || 'Required'];
    },
    calculatePercentage: {
      get() {
        return this.transItem.isCalculatePercentage;
      },
      set(value) {
        this.$emit('setCalculatePercentage', value);
      },
    },
    amountRemaining() {
      let totalRemainingReconAmount = amountToNumber(this.amount);
      for (let i = 0; i < this.editedItem.length; i += 1) {
        totalRemainingReconAmount -= amountToNumber(this.editedItem[i].amount);
      }
      this.$emit('totalRemainingReconAmount', totalRemainingReconAmount);
      return totalRemainingReconAmount;
    },
    percentageRemaining() {
      let remainingPercent = 100;
      for (let i = 0; i < this.editedItem.length; i += 1) {
        if (this.editedItem[i].percentageBreakdown) {
          remainingPercent -= this.editedItem[i].percentageBreakdown;
        }
      }
      return remainingPercent;
    },
  },
  methods: {
    amountFormat,
    amountToNumber,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    addOne() {
      this.$emit('input', [
        ...this.editedItem,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.editedItem.filter((candidate) => item !== candidate),
      ]);
    },
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    isRequiredUnitChecked(item) {
      const fundsArray = this.funds;
      for (let i = 0; i < (fundsArray || []).length; i += 1) {
        if ((extractId(item.fund) === extractId(fundsArray[i]))
        && (fundsArray[i].isRequiredUnit === true)) {
          return false;
        }
      }
      return true;
    },
    calculateAmount() {
      for (let i = 0; i < (this.editedItem || []).length; i += 1) {
        const calculatedAmount = (amountFormat(amountToNumber(
          this.editedItem[i].percentageBreakdown,
        )) * amountToNumber(this.amount)) / 100;
        this.editedItem[i].amount = amountFormat(calculatedAmount);
      }
      this.$nextTick(() => {
        if (this.calculatePercentage
         && (amountFormat(this.percentageRemaining)) === amountFormat(0)
          && this.amountRemaining > 0) {
          const arrLength = (this.editedItem || []).length;
          const finalAmt = amountToNumber(this.editedItem[arrLength - 1].amount)
           + amountToNumber(this.amountRemaining);
          this.editedItem[arrLength - 1].amount = amountFormat(finalAmt);
          this.somethingChanged();
        }
      });
      return this.editedItem;
    },
    shortcutHandler(e, index) {
      let totalAmount = 0;
      if (isTabOrPlus(e)) {
        if ((index + 1) === (this.editedItem || []).length) {
          for (let i = 0; i < (this.editedItem || []).length; i += 1) {
            totalAmount += amountToNumber(this.editedItem[i].amount);
            this.somethingChanged();
            if (totalAmount < amountToNumber(this.amount)) {
              this.addOne();
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="sass">
  .eft-rec-amount input
    text-align: right
  .recon-details-add-new-item
    margin-top: 16px
</style>
