<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <h5>Program</h5>
        <div>{{editedItem.program}}</div>
      </v-col>
      <v-col cols="3">
        <h5>Record type</h5>
        <div>{{editedItem.type}}</div>
      </v-col>
      <v-col cols="3">
        <h5>Nature of change (subtype)</h5>
        <div>{{editedItem.subtype}}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <h5>User</h5>
        <div>{{editedItem.username}}</div>
      </v-col>
      <v-col cols="3">
        <h5>When changed</h5>
        <div>{{toLocalDateTime(editedItem.when)}}</div>
      </v-col>
      <v-col cols="3">
        <h5>Remarks</h5>
        <div>{{editedItem.remarks}}</div>
      </v-col>
      <v-col cols="3" />
    </v-row>
    <v-row class="mt-6">
      <v-col>
        <h4>Changes</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <AuditChanges
          :baseCrudKey="baseCrudKey"
          :changes="editedItem.changes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  toLocalDateTime,
} from '../../../util/shared/vue-global';

import AuditChanges from './AuditChanges.vue';

export default {
  name: 'AuditEditedItem',
  components: {
    AuditChanges,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'audit-dialog',
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    changes() {
      return this.editedItem.changes || [];
    },
  },
  methods: {
    toLocalDateTime,
    ...mapActions('audit', [
      'loadAudits',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
    ]),
  },
};
</script>
