var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"grey lighten-5 mb-6"},[_c('h3',[_vm._v("Reconciliation Details")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items-per-page":-1,"items":_vm.editedItem,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-checkbox',{staticClass:"recon-details-calculate-percentage-flag",attrs:{"label":"Use % Breakdown"},model:{value:(_vm.calculatePercentage),callback:function ($$v) {_vm.calculatePercentage=$$v},expression:"calculatePercentage"}}),(_vm.amountToNumber(_vm.amountRemaining) > 0)?_c('v-btn',{staticClass:"mx-2 recon-details-add-new-item",attrs:{"color":"primary","fab":"","icon":"","x-small":""},on:{"click":_vm.addOne}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_vm._e()],1)]},proxy:true},{key:"item.taxDistrict",fn:function(ref){
var item = ref.item;
return [(_vm.transItem.isApportionLikeTax)?_c('v-select',{attrs:{"items":_vm.taxDistricts,"item-text":"taxDistrictDesc","item-value":"_id","dense":"","single-line":"","rules":_vm.reqdSelectValidation},on:{"blur":_vm.somethingChanged},model:{value:(item.taxDistrict),callback:function ($$v) {_vm.$set(item, "taxDistrict", $$v)},expression:"item.taxDistrict"}}):_vm._e()]}},{key:"item.fund",fn:function(ref){
var item = ref.item;
return [(!_vm.transItem.isApportionLikeTax)?_c('v-autocomplete',{attrs:{"label":"Fund","item-text":"fundDesc","item-value":"_id","items":_vm.funds,"dense":"","single-line":"","rules":_vm.reqdSelectValidation},on:{"blur":_vm.somethingChanged},model:{value:(item.fund),callback:function ($$v) {_vm.$set(item, "fund", $$v)},expression:"item.fund"}}):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"label":"Fund","item-text":"sourceDesc","item-value":"_id","items":_vm.sources,"dense":"","single-line":""},on:{"blur":_vm.somethingChanged},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}})]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [(!_vm.transItem.isApportionLikeTax)?_c('v-autocomplete',{attrs:{"label":"School/Unit Code","item-text":"unitDesc","item-value":"_id","items":_vm.unitCodes,"disabled":_vm.isRequiredUnitChecked(item),"dense":"","single-line":""},on:{"blur":_vm.somethingChanged},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}}):_vm._e()]}},{key:"item.percentageBreakdown",fn:function(ref){
var item = ref.item;
return [(_vm.calculatePercentage)?_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Percentage Breakdown"},on:{"blur":function($event){_vm.somethingChanged(), _vm.calculateAmount()}},model:{value:(item.percentageBreakdown),callback:function ($$v) {_vm.$set(item, "percentageBreakdown", $$v)},expression:"item.percentageBreakdown"}}):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Amount"},on:{"blur":_vm.somethingChanged,"keydown":[function($event){return _vm.shortcutHandler($event, index);},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}]},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","tabindex":"-1"},on:{"click":function($event){return _vm.removeOne(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text"},[_c('td',{attrs:{"id":"total-trans-amount"}},[_vm._v(" Total Reconciliation Amount: $"+_vm._s(_vm.amountFormat(_vm.amountRemaining))+" ")]),(_vm.calculatePercentage)?_c('td',{attrs:{"id":"total-percentage"}},[_vm._v(" Total Remaining Percentage: "+_vm._s(_vm.amountFormat(_vm.percentageRemaining))+"% ")]):_vm._e()])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }