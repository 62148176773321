<template>
  <div
    class="TransactionList">
      <v-overlay :value="overlay.flag">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="15"
          :value="overlay.value"
          color="primary"
        >
        {{ overlay.value }}
        </v-progress-circular>
      </v-overlay>
    <TransactionCriteria
      :baseCrudKey="baseCrudKey"
      @loadCRUDList="handleLoadCRUDList"
    />
    <CRUDList
      ref="crudList"
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :headers="headers"
      :showDate="true"
      :showTotal="true"
      :showExpand="true"
      :showSelect="true"
      :dataMapper="dataMapper"
      :baseCrudKey="baseCrudKey"
      :createNewItem="newItemDialog"
      :selectedItems="selectedItems"
      :itemTotal="selectedItemsAmount"
      :defaultSortBy="sortBy"
      :uploadFileParams="uploadFileParams"
      :loadingData="gridLoading.flag"
      :loadingText="gridLoading.text"
      toolbarTitle=""
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtTransaction"
      @deleteItem="deleteTransaction"
      @emitSelectedItem="emitSelectedItem"
      @activateItem="activateTransaction"
    >
      <template v-slot:[`editedItem`]="{}">
        <TransactionEditedItem
        :baseCrudKey="baseCrudKey"
        :attachmentConstants="attachmentConstants"
        @showOnClickApportionLikeTax="showOnClickApportionLikeTax"
        @showOnClickSpecialApportionment="showOnClickSpecialApportionment"
        @editItem="editItem"
        />
      </template>
      <template v-slot:[`additionalActions`]="{}">
          <v-row>
          <v-btn id="miscTransPrintFormatBtn"
            class="misc-trans-print-format-btn"
            text color="blue darken-1"
            @click="printReceiptFromDialogBox('receiptFormat2')">
          Print Receipt</v-btn>
          <v-btn id="miscTransPrintEONFormatBtn"
            class="misc-trans-print-format-btn"
            text color="blue darken-1"
            v-if="showTaxReceipt === false"
            @click="printReceiptFromDialogBox('printEON')">
            Print EON/App</v-btn>
            <v-btn
            class="misc-trans-tax-summary-print-btn"
            text color="blue darken-1"
            v-if="showTaxReceipt === true"
            @click="printTaxFundSummary()">
              Tax - Print Summary</v-btn>
          <v-btn id="miscTransPrint220"
            class="misc-trans-print-220-btn"
            text color="blue darken-1"
            v-if="showSpecialApportionment === true"
            @click="printReceiptFromDialogBox('printForm220')">
          Print Form 220</v-btn>
        </v-row>
        <AuditDialog id="miscTransAuditDialog"
          v-if="getActiveTransID"
          :identifier="getActiveTransID"
          activatorCaption="Audit History"
        />
        <UploadFile id="miscTransUploadFile"
          class="misctrans uploadFile"
          :bucketName="attachmentConstants.bucketName"
          :program="attachmentConstants.program"
          :parentObjectType="attachmentConstants.parentObjectType"
          :parentObjectId="getActiveTransID || ''"
          :itemIsSaved="!isNewItem"
        ><template v-slot:activator="{ activatorProps }">
        <v-btn
          color="primary"
          text
          @contextmenu.prevent="imagesRightClick(activatorProps.rightClick)"
          @click="imagesLeftClick(activatorProps.leftClick)"
        >
        {{activatorProps.caption}}
        </v-btn>
        </template>
       </UploadFile>
      </template>

      <template v-slot:[`selectDate`]="{}">
        <v-text-field class="mt-6 shrink misc-trans-select-date-to-mark-text"
          v-model="markingDateForItems"
          label="Select Date to Mark"
          type="date"
        ></v-text-field>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
      <template v-slot:[`duplicateMarking`]="{}">
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"
          >
            <v-icon
              class="mx-2 misc-trans-duplicate-content-icon" color="primary"
              fab icon small
              v-bind="attrs"
              v-on="on"
              :disabled="(selectedItems || []).length === 0"
              @click="miscDuplicateTrans"
            >
              {{icons.mdiContentDuplicate}}
            </v-icon>
          </template>
            <span>Duplicate</span>
        </v-tooltip>
      </div>
      <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
      <template v-slot:[`canDeleteSlot`]="{}"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mx-2 misc-trans-selected-delete-icon" color="primary"
              fab icon small
              v-bind="attrs"
              v-on="on"
              :disabled="(selectedItems || []).length === 0"
              @click="deleteItems"
            >
              {{icons.mdiDelete}}
            </v-icon>
          </template>
            <span>Delete</span>
        </v-tooltip>
         <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
      <template v-slot:[`additionalToolsAtRight`]="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="misc-trans-print-receipt-format-btn"
            depressed
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :disabled="printReceiptBtnValidation"
            @click="printReceiptOutsideDialogBox('receiptFormat2')"
          >
            <v-icon left>
              {{ icons.mdiPrinter }}
            </v-icon>
            Receipt
          </v-btn>
          </template>
            <span>Print Receipt</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              color="primary"
              class="ma-2 misc-trans-print-receipt-EON-btn"
              v-bind="attrs"
              v-on="on"
              :disabled="printReceiptBtnValidation"
              @click="printReceiptOutsideDialogBox('printEON')">
              <v-icon left>
                {{ icons.mdiPrinter }}
              </v-icon>
              EON
            </v-btn>
         </template>
            <span>Print EON/App</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              color="primary"
              class="ma-2 misc-trans-print-collection-summary"
              v-bind="attrs"
              v-on="on"
              :disabled="printReceiptBtnValidation"
              @click="printTaxFundSummaryFromList()">
              <v-icon left>
                {{ icons.mdiPrinter }}
              </v-icon>
              CS
            </v-btn>
          </template>
            <span>Print Collection Summary & Apportionment</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              color="primary"
              class="ma-2 misc-trans-print-form220"
              v-bind="attrs"
              v-on="on"
              :disabled="specialApportionmentBtnValidation"
              @click="printReceiptOutsideDialogBox('printForm220')">
              <v-icon left>
                {{ icons.mdiPrinter }}
              </v-icon>
              Form 220
            </v-btn>
          </template>
            <span>Print Form 220</span>
        </v-tooltip>
      </template>
    </CRUDList>
    <v-dialog
      v-model="miscTransAdditionalDialogBox"
      persistent
      width="35vw"
      height="145vh">
    <v-card>
      <v-card-title>
        <span class="headline"> {{ this.miscTransAdditionalDialog.headerText }}</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="miscTransAdditionalDialogAction(true)"> Yes </v-btn>
        <v-btn color="primary" text @click="miscTransAdditionalDialogAction(false)"> No </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {
  mdiContentDuplicate,
  mdiDelete,
  mdiPrinter,
} from '@mdi/js';
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import TransactionCriteria from './TransactionCriteria.vue';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import TransactionEditedItem from './TransactionEditedItem.vue';
import MiscTransactionList from './MiscTransactionList.mixin';
import AuditDialog from '../../common/audit/AuditDialog.vue';
import UploadFile from '../../common/UploadFile.vue';
import { getWorkstationNumber } from '../../../util/workstation-util';
import {
  amountToNumber,
  extractId,
  isEmptyObject,
  defaultOverlayConfig,
  defaultGridLoading,
  manageGridLoading,
} from '../../../util/shared/vue-global';
import {
  calculateFiscalYears,
  toFiscalYear,
  updateDateFormat,
} from '../../../util/shared/tmc-global';
import { clients } from '../../../util/clients';

const { backendRest } = clients.direct;

const getHeaders = () => {
  const headers = [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, formatter: 'file',
    },
    { text: 'Date', value: 'businessDate' },
    { text: 'Rec#', value: 'receiptNumber' },
    { text: 'Wkst', value: 'wkst' },
    {
      text: 'Amount', value: 'amount', formatter: 'amount', align: 'right',
    },
    { text: 'Received Of', value: 'whomStr' },
    { text: 'Description', value: 'shortDesc' },
    { text: '', value: 'data-table-expand' },
  ];
  return headers;
};

export default {
  mixins: [MiscTransactionList],
  data: () => ({
    baseCrudKey: 'miscTransaction',
    headers: [],
    dateForCriteria: ['fromBusinessDate', 'toBusinessDate'],
    miscDetailsElements: ['fund', 'source', 'unit', 'taxDistrict'],
    markingDateForItems: undefined,
    activeTransItem: undefined,
    activeEditedItem: {},
    showTaxReceipt: false,
    showSpecialApportionment: false,
    overlay: defaultOverlayConfig(),
    attachmentConstants: {
      bucketName: 'misc',
      program: 'misc',
      parentObjectType: 'miscTransaction',
    },
    uploadFileParams: {
      bucketName: 'misc',
      program: 'misc',
      parentObjectType: 'miscTransaction',
    },
    uploadFileClickParams: {
      leftClickFlag: false,
      leftClickDef: undefined,
      rightClickFlag: false,
      rightClickDef: undefined,
    },
    miscTransAdditionalDialogBox: false,
    miscTransAdditionalDialog: {
      headerText: '',
    },
    icons: {
      mdiContentDuplicate,
      mdiDelete,
      mdiPrinter,
    },
    sortBy: [
      {
        column: 'businessDate',
        direction: 'desc',
      },
      {
        column: 'receiptNumber',
        direction: 'desc',
      },
    ],
    gridLoading: defaultGridLoading(),
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.criteriaState = updateDateFormat(this.criteriaState, this.dateForCriteria);
    this.initializeMiscFiscalYears();
    this.markingDateForItems = this.todaysDate; // by default
    this.loadConfig({ requiredScope: 'miscConfig' });
  },
  components: {
    CRUDList,
    TransactionCriteria,
    TransactionEditedItem,
    AuditDialog,
    UploadFile,
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
      'items',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      miscRegYears: (state) => state.misc.miscTransaction.regYears,
      storedSelectedItems: (state) => state.misc.miscTransaction.selectedItems,
      nextReceiptNumber: (state) => state.misc.miscTransaction.nextReceiptNumber,
      businessDateRange: (state) => state.misc.miscTransaction.businessDateRange,
      nextTransNumber: (state) => state.common.cmnTransNumber.nextTransNumber,
      miscSettingItem: (state) => state.SystemConfig.miscSettingItem,
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    selectedItems: {
      get() {
        return this.storedSelectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      },
    },
    fiscalYearList() {
      const registerdYears = (this.miscRegYears || {});
      const allowAll = true;
      return calculateFiscalYears(registerdYears, allowAll);
    },
    printReceiptBtnValidation() {
      return (this.selectedItems || []).length === 0
       || (this.selectedItems || []).length > 1;
    },
    specialApportionmentBtnValidation() {
      if ((this.selectedItems || []).length === 1) {
        const isTransSpecialApporitonment = this.selectedItems[0].isSpecialApportionment;
        return !isTransSpecialApporitonment;
      }
      return true;
    },
    getActiveTransID() {
      return this.activeTransItem && this.activeTransItem._id;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    selectedItemsAmount() {
      const amountSum = (this.selectedItems || []).reduce((acc, obj) => acc
        + amountToNumber(obj.amount), 0);
      return amountSum;
    },
  },
  methods: {
    manageGridLoading,
    ...mapActions(['reAuth', 'flashError']),
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions('misc/miscTransaction', [
      'loadMiscTransaction',
      'upsertMiscTrans',
      'loadFiscalRegYears',
      'loadLastReceiptNo',
      'loadLastTransactionNo',
      'deleteMiscTransaction',
      'loadBusinessDateRange',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapActions('shared/source', [
      'loadCmnSource',
    ]),
    ...mapActions('misc/schoolUnit', [
      'loadMiscUnitCode',
    ]),
    ...mapActions('common/cmnWhom', [
      'loadCmnWhom',
    ]),
    ...mapActions('shared/taxDistrict', [
      'loadTaxDistrict',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
      'setIndex',
    ]),
    ...mapMutations('files', [
      'setFiles',
    ]),
    ...mapMutations('misc/miscTransaction', [
      'setSelectedItems',
      'setReceiptPulse',
      'setReceiptPolling',
    ]),
    ...mapMutations('common/cmnTransNumber', [
      'setTransNoPolling',
      'setTransNoPulse',
    ]),
    handleLoadCRUDList(criteriaArgs = {}) {
      const { baseCrudKey } = this;
      const criteria = this.updateCriteriaForReq();
      manageGridLoading(this, true, 'Searching...');
      this.loadMiscTransaction({
        criteria,
        baseCrudKey,
        criteriaArgs,
      }).then(() => {
        this.setDefaultSelectedItems(criteriaArgs);
      }).catch((e) => {
        console.warn(e);
        this.setDefaultSelectedItems(criteriaArgs);
      }).finally(() => {
        manageGridLoading(this, false);
      });
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    async initializeMiscFiscalYears() {
      await this.loadFiscalRegYears({ baseCrudKey: this.baseCrudKey });
      const regYears = this.fiscalYearList || [];
      if ((regYears || []).length > 0) {
        this.criteriaState.fiscalYear = (regYears[regYears.length - 1] || {}).year;
      }
      this.handleLoadCRUDList();
      this.loadLists(this.criteriaState.fiscalYear);
    },
    async upsrtTransaction(item) {
      /* For new item check wkst, and if it is not avaible exit early with message */
      if (this.isNewItem && !item.wkst) {
        this.flashError('Error: While upserting receipt, workstation number not exist!');
        return {};
      }
      const { baseCrudKey } = this;
      const updatedItem = this.deleteFieldsNotRequireToUpsert(item);
      updatedItem.amount = amountToNumber(updatedItem.amount);
      if (updatedItem.bank) {
        updatedItem.bank = extractId(updatedItem.bank);
      }
      if (!updatedItem.isApportionLikeTax) {
        updatedItem.taxYear = null;
      }
      if ((updatedItem.details || []).length > 0) {
        updatedItem.details = updatedItem.details.map((element) => {
          const amount = amountToNumber(element.amount);
          const detailItem = { ...element };
          if (updatedItem.isApportionLikeTax) {
            detailItem.fund = null;
            detailItem.unit = null;
          }
          if (!updatedItem.isApportionLikeTax) {
            detailItem.taxDistrict = null;
          }
          if (detailItem.fund) {
            detailItem.fund = extractId(detailItem.fund);
          }
          if (detailItem.source) {
            detailItem.source = extractId(detailItem.source);
          }
          if (detailItem.unit) {
            detailItem.unit = extractId(detailItem.unit);
          }
          if (detailItem.taxDistrict) {
            detailItem.taxDistrict = extractId(detailItem.taxDistrict);
          }
          if (updatedItem.isCalculatePercentage) {
            detailItem.percentageBreakdown = amountToNumber(detailItem.percentageBreakdown);
          } else {
            detailItem.percentageBreakdown = null;
          }
          return { ...detailItem, amount };
        });
      }
      if ((updatedItem.paymentDetails || []).length > 0) {
        updatedItem.paymentDetails = updatedItem.paymentDetails.map((element) => {
          const newUpdatedElement = this.buildPaymentItem(element);
          return {
            ...newUpdatedElement,
          };
        });
      }
      /*
        Trying to reduce the chance of skipping receipt number,
        stopping polling just before upserting
      */
      this.setReceiptPolling(undefined);
      this.setTransNoPolling(undefined);
      const response = await this.upsertMiscTrans({
        item: updatedItem,
        baseCrudKey,
      });
      if (!isEmptyObject(response)) {
        this.updateActiveTransItem(response);
      }
      this.setDefaultSelectedItems();
      return response;
    },
    buildPaymentItem(element) {
      if (element.paymentType) {
        const amount = amountToNumber(element.amount);
        const updatedElement = {
          paymentType: element.paymentType,
          amount,
        };
        if (element.paymentType === 'CASH') {
          updatedElement.cashReceived = amountToNumber(element.cashReceived);
          updatedElement.cashChange = amountToNumber(element.cashChange);
        }
        if (element.paymentType === 'CREDIT') {
          updatedElement.creditType = element.creditType;
        }
        if (element.paymentType === 'CHECK') {
          updatedElement.checkNumber = element.checkNumber;
          updatedElement.checkPaidBy = element.checkPaidBy;
        }
        if (element.paymentType === 'EFT') {
          updatedElement.eftType = element.eftType;
        }
        return { ...updatedElement };
      }
      return { ...element };
    },
    loadLists(fiscalYear) {
      const currentFiscalYear = fiscalYear || toFiscalYear(new Date(this.todaysDate));
      this.loadFunds({
        criteria: {
          fiscalYear: currentFiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'sharedFund',
      });
      this.loadCmnSource({
        criteria: {
          fiscalYear: currentFiscalYear,
          isSkipMiscellaneousTransaction: false,
          sortBy: [
            {
              column: 'sourceSubSourceNumber',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'sharedSource',
      });
      this.loadMiscUnitCode({
        criteria: {
          fiscalYear: currentFiscalYear,
          sortBy: [
            {
              column: 'unitCode',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'miscUnitCode',
      });
      this.loadCmnWhom({
        criteria: {
          fiscalYear: currentFiscalYear,
        },
        baseCrudKey: 'miscWhom',
      });
      this.loadTaxDistrict({
        criteria: {},
        baseCrudKey: 'sharedTaxDistrict',
      });
    },
    newItemDialog() {
      this.setFiles([]);
      let newItem = {};
      newItem = {
        businessDate: this.todaysDate,
        fiscalYear: this.criteriaState.fiscalYear || toFiscalYear(new Date(this.todaysDate)),
        // by default the new item should be miscellaneous if nothing is selected in criteria
        isApportionLikeTax: false,
        receiptNumber: undefined,
        isCalculatePercentage: false,
        whomStr: '',
        description: '',
        amount: 0.00,
        taxYear: null,
        wkst: null,
        transactionNumber: null,
        details: [{
          fund: null,
          source: null,
          unit: null,
          taxDistrict: null,
          percentageBreakdown: 0,
          amount: 0,
        }],
      };
      this.loadLastReceiptNo({ fiscalYear: newItem.fiscalYear });
      this.loadLastTransactionNo({ fiscalYear: newItem.fiscalYear });
      if (this.miscSettingItem && this.miscSettingItem.usedLastBusinessDate) {
        this.loadBusinessDateRange().then(() => {
          newItem.businessDate = new Date(this.businessDateRange.max) > new Date()
            ? this.businessDateRange.max : this.todaysDate;
        }).catch((e) => {
          console.warn(e);
          newItem.businessDate = this.todaysDate;
        });
      }
      if (this.criteriaState.fiscalYear) {
        if (this.criteriaState.fund && this.criteriaState.fund.length > 0) {
          newItem.details[0].fund = this.criteriaState.fund;
        }
        if (this.criteriaState.source && this.criteriaState.source.length > 0) {
          newItem.details[0].source = this.criteriaState.source;
        }
        if (this.criteriaState.unit && this.criteriaState.unit.length > 0) {
          newItem.details[0].unit = this.criteriaState.unit;
        }
        if (this.criteriaState.taxDistrict && this.criteriaState.taxDistrict.length > 0) {
          newItem.details[0].taxDistrict = this.criteriaState.taxDistrict;
        }
        if (this.miscSettingItem.cashAndCheckDepositBank) {
          newItem.bank = this.miscSettingItem.cashAndCheckDepositBank;
        }
        if (this.miscSettingItem.taxYear) {
          newItem.taxYear = this.miscSettingItem.taxYear;
        }
        if (this.miscSettingItem.defaultSpecialApportionment) {
          newItem.isSpecialApportionment = this.miscSettingItem.defaultSpecialApportionment;
        }
        newItem.wkst = getWorkstationNumber();
      }
      return newItem;
    },
    updateCriteriaForReq() {
      const updatedCriteria = updateDateFormat(this.criteriaState, this.dateForCriteria);
      return updatedCriteria;
    },
    dataMapper(dataItems) {
      const listData = [];
      for (let i = 0; i < dataItems.length; i += 1) {
        listData.push(dataItems[i]);
        listData[i].shortDesc = `${(dataItems[i].description || '').substring(0, 20)}...`;
        if (Array.isArray(dataItems[i].details) && dataItems[i].details.length > 0) {
          if (dataItems[i].details.length === 1) {
            listData[i].fundNo = (dataItems[i].details[0].fund || {}).fund;
            listData[i].sourceSubSourceNumber = (dataItems[i].details[0].source || {})
              .sourceSubSourceNumber;
            listData[i].unitCodeNumber = (dataItems[i].details[0].unit || {}).unitCodeNumber;
          }
        }
      }
      return listData;
    },
    emitSelectedItem(item) {
      this.selectedItems = item;
    },
    setDefaultSelectedItems(criteriaArgs = {}) {
      if (criteriaArgs.searchMode === 'wildcard') {
        this.$nextTick(() => {
          this.selectedItems = [...this.selectedItems];
        });
      } else {
        this.$nextTick(() => {
          this.selectedItems = [];
        });
      }
    },
    buildMiscDetailsForReq(detail) {
      const miscDetail = { ...detail };
      this.miscDetailsElements.forEach((element) => {
        if (detail[element]) {
          miscDetail[element] = extractId(miscDetail[element]);
        }
      });
      return miscDetail;
    },
    deleteTransaction(item) {
      const { baseCrudKey } = this;
      this.deleteMiscTransaction({ item, baseCrudKey }).then(() => {
        this.setDefaultSelectedItems();
      }).catch((e) => {
        console.warn(e);
        this.setDefaultSelectedItems();
      });
    },
    async deleteItems() {
      if (window.confirm(`CAREFUL! Are you sure you want to DELETE ${this.selectedItems.length} record/s?`)) {
        for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
          const item = this.selectedItems[i];
          this.deleteTransaction(item);
        }
      }
      this.selectedItems = [];
    },
    async printReceiptFromDialogBox(receiptName) {
      const isValidForm = this.$refs.crudList.$refs.crudDialog.validateForm();
      if (isValidForm) {
        const miscTransId = await this.upsertMiscData();
        this.printReceipt(receiptName, miscTransId);
      }
    },
    async printReceiptOutsideDialogBox(receiptType) {
      const miscTransId = this.selectedItems.map((t) => t._id).join(',');
      this.printReceipt(receiptType, miscTransId);
    },
    async printReceipt(receiptType, miscTransId) {
      const { jwt } = await this.reAuth();
      let url;
      if (receiptType === 'receiptFormat2') {
        url = `${backendRest.defaults.baseURL}/misc/miscPrintReceiptFormat2?miscTransId=${miscTransId}&token=${jwt}`;
      } else if (receiptType === 'printEON') {
        url = `${backendRest.defaults.baseURL}/misc/miscPrintEON?miscTransId=${miscTransId}&token=${jwt}`;
      } else if (receiptType === 'printForm220') {
        url = `${backendRest.defaults.baseURL}/misc/miscPrintForm220?miscTransId=${miscTransId}&token=${jwt}`;
      }
      window.open(url, '_blank');
    },
    activateTransaction(val) {
      this.activeTransItem = val;
    },
    async imagesLeftClick(leftClick) {
      if (this.isNewItem) {
        await this.$refs.crudList.$refs.crudDialog.apply();
        this.uploadFileClickParams.leftClickFlag = true;
        this.uploadFileClickParams.leftClickDef = leftClick;
      } else {
        leftClick();
      }
    },
    async imagesRightClick(rightClick) {
      if (this.isNewItem) {
        await this.$refs.crudList.$refs.crudDialog.apply();
        this.uploadFileClickParams.rightClickFlag = true;
        this.uploadFileClickParams.rightClickDef = rightClick;
      } else {
        rightClick();
      }
    },
    settingFields(res) {
      this.activeTransItem = res;
      this.setItem([this.baseCrudKey, res]);
      this.setIndex([this.baseCrudKey, 0]);
      this.setReceiptPolling(undefined);
      this.setTransNoPolling(undefined);
    },
    updateActiveTransItem(res) {
      if (this.uploadFileClickParams.leftClickFlag) {
        this.settingFields(res);
        this.$nextTick(() => {
          this.imagesLeftClick(this.uploadFileClickParams.leftClickDef);
          this.uploadFileClickParams.leftClickFlag = false;
          this.uploadFileClickParams.leftClickDef = undefined;
        });
      } else if (this.uploadFileClickParams.rightClickFlag) {
        this.settingFields(res);
        this.$nextTick(() => {
          this.imagesRightClick(this.uploadFileClickParams.rightClickDef);
          this.uploadFileClickParams.rightClickFlag = false;
          this.uploadFileClickParams.rightClickDef = undefined;
        });
      }
      return false;
    },
    showOnClickApportionLikeTax(value) {
      this.showTaxReceipt = value;
      return value;
    },
    showOnClickSpecialApportionment(value) {
      this.showSpecialApportionment = value;
      return value;
    },
    editItem(val) {
      this.activeEditedItem = val;
    },
  },
};
</script>

<style>

</style>
