/*
  * This mixin is very specific for misc program transactionList
  * It is a kind of misc-transactionList extended file
  * Be careful before mixin it anywhere else
*/
import gql from 'graphql-tag';
import { mapActions } from 'vuex';
import { bulkResult } from '../../../util/shared/graphql-global';
import {
  toFiscalYear,
  toIsoDate,
} from '../../../util/shared/tmc-global';
import {
  defaultOverlayConfig,
  extractId,
  incrementOverlay,
  isEmptyObject,
} from '../../../util/shared/vue-global';
import { clients } from '../../../util/clients';
import { getWorkstationNumber } from '../../../util/workstation-util';

const { backend, backendRest } = clients.direct;

const calculateTaxFundSummary = async (miscTransId, self) => {
  const criteria = {
    ids: miscTransId,
  };
  let results;
  try {
    results = await backend.query({
      query: gql` query calculateTaxFundSummary($criteria: calculateTaxFundSummaryCriteriaType) {
        calculateTaxFundSummary(criteria: $criteria) {
            ${bulkResult}
          }
        }`,
      variables: { criteria },
      fetchPolicy: 'no-cache',
    });
  } catch (error) {
    self.flashError('Unable to calculate tax summary. Some server error.');
  }
  return results;
};

const createGroupFromArr = ({ transArr = [], keyName }) => {
  const groupData = {};

  for (let j = 0; j < (transArr || []).length; j += 1) {
    const newObj = { ...transArr[j] };
    groupData[`${newObj[keyName]}`] = newObj;
  }
  return groupData;
};

export default {
  computed: {
  },
  incrementOverlay,
  methods: {
    ...mapActions(['flashInfo', 'flashError']),
    deleteFieldsNotRequireToUpsert(item, isDeleteId = false) {
      const updatedItem = { ...item };
      if (isDeleteId) {
        delete updatedItem._id;
      }
      delete updatedItem.fundNo;
      delete updatedItem.sourceSubSourceNumber;
      delete updatedItem.unitCodeNumber;
      delete updatedItem.shortDesc;
      delete updatedItem.hasFiles;
      return updatedItem;
    },
    updateMiscDetails(detail, dbDataGrps) {
      const {
        fundsResultGroup, sourceResultGroup, taxDistrictResultGroup,
        miscUnitCodeResultGrp,
      } = dbDataGrps;
      const miscDetail = { ...detail };
      this.miscDetailsElements.forEach(async (element) => {
        if (element === 'fund') {
          if (!isEmptyObject(fundsResultGroup) && miscDetail.fund) {
            miscDetail.fund = fundsResultGroup[miscDetail.fund.fund]
              ? fundsResultGroup[miscDetail.fund.fund]._id : null;
          } else {
            miscDetail.fund = null;
          }
        } else if (element === 'source') {
          if (!isEmptyObject(sourceResultGroup) && miscDetail.source) {
            miscDetail.source = sourceResultGroup[miscDetail.source.sourceSubSourceNumber]
              ? sourceResultGroup[miscDetail.source.sourceSubSourceNumber]._id : null;
          } else {
            miscDetail.source = null;
          }
        } else if (element === 'taxDistrict') {
          if (!isEmptyObject(taxDistrictResultGroup) && miscDetail.taxDistrict) {
            miscDetail.taxDistrict = taxDistrictResultGroup[miscDetail.taxDistrict.districtCode]
              ? taxDistrictResultGroup[miscDetail.taxDistrict.districtCode]._id : null;
          } else {
            miscDetail.taxDistrict = null;
          }
        } else if (element === 'unit') {
          if (!isEmptyObject(miscUnitCodeResultGrp) && miscDetail.unit) {
            miscDetail.unit = miscUnitCodeResultGrp[miscDetail.unit.unitCode]
              ? miscUnitCodeResultGrp[miscDetail.unit.unitCode]._id : null;
          } else {
            miscDetail.unit = null;
          }
        }
      });
      return miscDetail;
    },
    async miscTransAdditionalDialogAction(isResponseFlag) {
      this.miscTransAdditionalDialogBox = false;
      this.miscTransAdditionalDialog.headerText = '';
      await this.duplicateTrans(isResponseFlag);
    },
    async miscDuplicateTrans() {
      if (window.confirm(`Are you sure want to duplicate ${(this.selectedItems || []).length} record/s?`)) {
        const miscRegYears = this.fiscalYearList || [];
        const currentFiscalYear = (miscRegYears[miscRegYears.length - 1] || {}).year
        || toFiscalYear(new Date(this.todaysDate));
        /* While duplicating Misc Receipts check if user has selected another Fiscal Year
         in the criteriaState, if Fiscal Year is other than current Fiscal Year then show
         dialog box */
        if (this.criteriaState.fiscalYear !== currentFiscalYear) {
          this.miscTransAdditionalDialogBox = true;
          this.miscTransAdditionalDialog.headerText = 'Do you want to create this in most current fiscal year ?';
        } else {
          await this.duplicateTrans(false);
        }
      }
    },
    async duplicateTrans(isUseLatestFiscalYear) {
      let fiscalYear;
      const miscRegYears = this.fiscalYearList || [];
      const latestFiscalYear = (miscRegYears[miscRegYears.length - 1] || {}).year
        || toFiscalYear(new Date(this.todaysDate));
      if (isUseLatestFiscalYear) {
        fiscalYear = latestFiscalYear;
      } else {
        fiscalYear = this.selectedItems[0] ? this.selectedItems[0].fiscalYear : latestFiscalYear;
      }
      const criteria = { fiscalYear };
      const fundsResultArr = await this.loadFunds({ criteria });
      const fundsResultGroup = createGroupFromArr({ transArr: fundsResultArr, keyName: 'fund' });

      const sourceResultArr = await this.loadCmnSource({ criteria });
      const sourceResultGroup = createGroupFromArr({
        transArr: sourceResultArr,
        keyName: 'sourceSubSourceNumber',
      });

      const taxDistrictResultArr = await this.loadTaxDistrict({ criteria: {} });
      const taxDistrictResultGroup = createGroupFromArr({
        transArr: taxDistrictResultArr,
        keyName: 'districtCode',
      });

      const miscUnitCodeResultArr = await this.loadMiscUnitCode({ criteria });
      const miscUnitCodeResultGrp = createGroupFromArr({ transArr: miscUnitCodeResultArr, keyName: 'unitCode' });

      const dbDataGrps = {
        fundsResultGroup,
        sourceResultGroup,
        taxDistrictResultGroup,
        miscUnitCodeResultGrp,
      };

      this.overlay.flag = true;
      const isoData = toIsoDate(this.markingDateForItems);
      const wkst = getWorkstationNumber();
      for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
        const copiedItem = JSON.parse(JSON.stringify(this.selectedItems[i]));
        const upsItem = this.deleteFieldsNotRequireToUpsert(copiedItem, true);
        upsItem.fiscalYear = fiscalYear;
        await this.loadLastReceiptNo({ fiscalYear: upsItem.fiscalYear });
        await this.loadLastTransactionNo({ fiscalYear: upsItem.fiscalYear });
        upsItem.wkst = wkst;
        upsItem.receiptNumber = this.nextReceiptNumber;
        upsItem.transactionNumber = this.nextTransNumber;
        upsItem.bank = extractId(upsItem.bank);
        upsItem.businessDate = isoData;
        upsItem.amount = 0.00;
        upsItem.details = (upsItem.details || []).map((element) => {
          const updatedElement = this.updateMiscDetails(element, dbDataGrps);
          updatedElement.amount = 0.00;
          return updatedElement;
        });
        await this.upsertMiscTrans({ baseCrudKey: this.baseCrudKey, item: upsItem });
        incrementOverlay(this, (this.selectedItems || []).length, i);
      }
      this.criteriaState.fromBusinessDate = isoData;
      this.criteriaState.fiscalYear = fiscalYear;
      this.$nextTick(() => {
        this.handleLoadCRUDList();
      });
      this.overlay = defaultOverlayConfig();
      this.selectedItems = [];
    },
    async upsertMiscData() {
      const result = await this.upsrtTransaction(this.activeEditedItem);
      this.settingFields(result);
      return result._id;
    },
    async printTaxFundSummary() {
      const isValidForm = this.$refs.crudList.$refs.crudDialog.validateForm();
      if (isValidForm) {
        const miscTransId = await this.upsertMiscData();
        this.generateAndFetchTaxFundSummaryWorkFile(miscTransId);
      }
    },
    async printTaxFundSummaryFromList() {
      const miscTransId = this.selectedItems.map((t) => t._id).join(',');
      this.generateAndFetchTaxFundSummaryWorkFile(miscTransId);
    },
    async generateAndFetchTaxFundSummaryWorkFile(miscTransIds) {
      const { jwt } = await this.reAuth();
      const result = await calculateTaxFundSummary(miscTransIds, this);
      if (result) {
        const urlForCollectionSummary = `${backendRest.defaults.baseURL}/misc/collection-summary-from-levies-apportionment?ids=${miscTransIds}&token=${jwt}`;
        const urlForDistrictBreakdown = `${backendRest.defaults.baseURL}/misc/apportionment-school-district-breakdown?token=${jwt}`;
        this.$nextTick(() => {
          window.open(urlForCollectionSummary, '_blank');
        });
        this.$nextTick(() => {
          window.open(urlForDistrictBreakdown, '_blank');
        });
      }
    },
  },
};
