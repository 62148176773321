<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-bind="attrs" v-on="on"
        v-model="getMaxNoInputLocal"
        :label="inputLabel"
        :rules="rules"
        :readonly="!inputFieldOverride || isNewItem"
        @dblclick.stop="overrideMaxNumber"
        color="red"
        type="number"
        :class="ReceiptPulseClass"
      ></v-text-field>
    </template>
    <span>{{ inputHoverCaption }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'GetMaxReceiptNumber',
  props: {
    fiscalYear: [Number, String],
    fieldType: {
      type: String,
      default: 'Item',
    },
    receiptNumber: [Number, String],
    isNewItem: Boolean,
    rules: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    inputFieldOverride: false,
  }),
  model: {
    prop: 'receiptNumber',
    event: 'maxNumberChg',
  },
  created() {
    if (this.fiscalYear) {
      this.loadLastReceiptNo({ fiscalYear: this.fiscalYear });
    }
  },
  computed: {
    ...mapState({
      nextReceiptNumber: (state) => state.misc.miscTransaction.nextReceiptNumber,
      receiptPulse: (state) => state.misc.miscTransaction.receiptPulse,
    }),
    ...mapGetters('base/crud', [
      'item',
    ]),
    ReceiptPulseClass() {
      return this.receiptPulse ? 'color-anim-pulse' : 'color-anim-black';
    },
    inputLabel() {
      if (this.nextReceiptNumber === undefined) {
        return `${this.fieldType} # (last: ...)`;
      }
      return `${this.fieldType} # (last: ${this.nextReceiptNumber - 1})`;
    },
    getMaxNoInputLocal: {
      get() {
        return this.receiptNumber;
      },
      set(value) {
        this.$emit('maxNumberChg', value);
      },
    },
    inputHoverCaption() {
      if (this.isNewItem) {
        return `${this.fieldType}  must be saved before its number can be changed`;
      }
      return 'Double-click to override (use ⚠️ caution)';
    },
  },
  methods: {
    ...mapActions('misc/miscTransaction', [
      'loadLastReceiptNo',
    ]),
    overrideMaxNumber() {
      if (!this.inputFieldOverride) {
        this.inputFieldOverride = true;
      }
    },
  },
};
</script>

<style>

</style>
