<template>
  <v-data-table
    :items="changes"
    :headers="headers"
    dense
    class="elevation-1"
    :items-per-page="itemsPerPage"
    hide-default-footer
  >
    <template v-slot:[`item`]="{ item, headers }">
      <CRUDRow
        :canEdit="false"
        :canDelete="false"
        :item="item"
        :headers="headers"
      />
    </template>
  </v-data-table>
</template>

<script>
import CRUDRow from '../base/crud/CRUDRow.vue';

export default {
  name: 'TransactionCriteria',
  components: {
    CRUDRow,
  },
  data: () => ({
    headers: [
      { text: 'Column', value: 'key' },
      { text: 'Changed from', value: 'from', formatter: 'auto' },
      { text: 'Changed to', value: 'to', formatter: 'auto' },
    ],
    itemsPerPage: 50, // Just a rough limit
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'audit',
    },
    changes: {
      type: Array,
      default: () => ([]),
    },
  },
};

</script>
