<template>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-bind="attrs" v-on="on"
          v-model="getMaxNoInputLocal"
          :label="inputLabel"
          :readonly="!inputFieldOverride"
          @dblclick.stop="overrideMaxNumber"
          color="red"
          type="number"
          :class="transNoPulseClass"
          :rules="rules"
        ></v-text-field>
      </template>
      <span>{{ inputHoverCaption }}</span>
    </v-tooltip>
  </template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'GetMaxTransNumber',
  props: {
    fiscalYear: [Number, String],
    fieldType: {
      type: String,
      default: 'Item',
    },
    transactionNumber: [Number, String],
    cmnBaseCrudKey: {
      type: String,
      default: '',
    },
    loadLastTransactionNo: {
      type: Function,
      default: () => ({}),
    },
    rules: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    inputFieldOverride: false,
  }),
  model: {
    prop: 'transactionNumber',
    event: 'maxNumberChg',
  },
  created() {
    if (this.fiscalYear) {
      this.loadLastTransactionNo({
        fiscalYear: this.fiscalYear,
      });
    }
  },
  computed: {
    ...mapState({
      nextTransNumber: (state) => state.common.cmnTransNumber.nextTransNumber,
      transNoPulse: (state) => state.common.cmnTransNumber.transNoPulse,
    }),
    ...mapGetters('base/crud', [
      'item',
    ]),
    transNoPulseClass() {
      return this.transNoPulse ? 'color-anim-pulse' : 'color-anim-black';
    },
    inputLabel() {
      if (this.nextTransNumber === undefined) {
        return `${this.fieldType} # (last: ...)`;
      }
      return `${this.fieldType} # (last: ${this.nextTransNumber - 1})`;
    },
    getMaxNoInputLocal: {
      get() {
        return this.transactionNumber;
      },
      set(value) {
        this.$emit('maxNumberChg', value);
      },
    },
    inputHoverCaption() {
      return 'Double-click to override (use ⚠️ caution)';
    },
  },
  methods: {
    overrideMaxNumber() {
      if (!this.inputFieldOverride) {
        this.inputFieldOverride = true;
      }
    },
  },
};
</script>

<style>

</style>
