<template>
 <div>
    <CRUDCriteria
      :showReset="true"
      :showSearch="false"
    >
      <template v-slot:criteriaRows>
        <v-row>
          <v-col cols="3">
            <FySelect
              id="misc-criteria-FY"
              v-model="criteriaState.fiscalYear"
              :fyRange="fiscalYearRange"
              @input="searchByCriteria(); loadLists();"
            />
          </v-col>
          <v-col cols="3">
            <v-select id="misc-criteria-type"
              v-model="criteriaState.isApportionLikeTax"
              :items="isApportionLikeTaxOptions"
              item-text="caption"
              item-value="value"
              label="Apportion Like Taxes"
              @change="searchByCriteria"
              >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select id="misc-criteria-special-apportionment"
              v-model="criteriaState.isSpecialApportionment"
              :items="isSpecialApportionmentOptions"
              item-text="caption"
              item-value="value"
              label="Special Apportionment"
              @change="searchByCriteria"
              >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select id="misc-criteria-isVoid"
              v-model="criteriaState.isVoid"
              :items="isVoidOptions"
              item-text="caption"
              item-value="value"
              label="Type"
              @change="searchByCriteria"
              >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-autocomplete id="misc-criteria-fund"
              v-model="criteriaState.fund"
              label="By Fund"
              item-text="fundDesc"
              item-value="_id"
              :items="funds"
              @change="searchByCriteria"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete id="misc-criteria-source"
              v-model="criteriaState.source"
              label="By Source"
              item-text="sourceDesc"
              item-value="_id"
              :items="sources"
              @change="searchByCriteria"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete id="misc-criteria-school/unitCode"
              v-model="criteriaState.unit"
              label="By School/Unit Code"
              item-text="unitDesc"
              item-value="_id"
              :items="unitCodes"
              @change="searchByCriteria"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete id="misc-criteria-taxDistrict"
              v-model="criteriaState.taxDistrict"
              label="By Tax District"
              item-text="taxDistrictDesc"
              item-value="_id"
              :items="taxDistricts"
              @change="searchByCriteria"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-text-field
              id="transactionWildCard"
              v-model="criteriaState.wildcard"
              label="Wildcard search (receipt number #, description, amount or received)"
              @input="wildcardSearchDelayed"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <ClarionDateControl
              v-model="criteriaState.fromBusinessDate"
              :isRequired="false"
              :label="`From (Business Date)`"
              :isIsoDate="true"
              @blurred="searchByCriteria"
            />
          </v-col>
          <v-col cols="3">
            <ClarionDateControl
              v-model="criteriaState.toBusinessDate"
              :isRequired="false" :label="`To`"
              :isIsoDate="true"
              @blurred="searchByCriteria"
            />
          </v-col>
        </v-row>
      </template>
    </CRUDCriteria>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import FySelect from '../../common/FySelect.vue';
import {
  calculateFiscalYears,
} from '../../../util/shared/tmc-global';
import MiscTransactionListMixin from './MiscTransactionList.mixin';

const fundCrudKey = 'sharedFund';
const sourceCrudKey = 'sharedSource';
const unitCrudKey = 'miscUnitCode';

export default {
  name: 'TransactionCriteria',
  mixins: [MiscTransactionListMixin],
  components: {
    CRUDCriteria,
    ClarionDateControl,
    FySelect,
  },
  data: () => ({
    baseCrudKey: 'miscTransaction',
    delayTimerId: undefined,
    taxDistrictCrudKey: 'sharedTaxDistrict',
  }),
  created() {
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.misc.miscTransaction.regYears || {};
        return calculateFiscalYears(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    funds() {
      const fundObjs = this.items(fundCrudKey) || [];
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      return [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    sources() {
      const sourceObjs = this.items(sourceCrudKey) || [];
      const updatedSourceObj = sourceObjs.map((s) => {
        const firstPartDesc = `${s.sourceSubSourceNumber} ${s.sourceDescription || ''}`;
        const secondPartDesc = s.subSourceDescription;
        return {
          ...s,
          sourceDesc: secondPartDesc == null || '' ? `${s.sourceSubSourceNumber} ${s.sourceDescription}` : `${firstPartDesc} - ${secondPartDesc}`,
        };
      });
      return [{ _id: null, sourceNumber: '', sourceDesc: '' }, ...updatedSourceObj];
    },
    unitCodes() {
      const unitCodeObjs = this.items(unitCrudKey) || [];
      const updatedUnitObj = unitCodeObjs.map((u) => ({
        ...u,
        unitDesc: `${u.unitCodeNumber} ${u.description || ''}`,
      }));
      return [{ _id: null, unitCodeNumber: '', unitDesc: '' }, ...updatedUnitObj];
    },
    taxDistricts() {
      const taxDistrictObjs = this.items(this.taxDistrictCrudKey) || [];
      const updatedTaxDistObj = taxDistrictObjs.map((t) => ({
        ...t,
        taxDistrictDesc: `${t.districtCode} ${t.districtName || ''}`,
      }));
      return [{ _id: null, districtCode: '', taxDistrictDesc: '' }, ...updatedTaxDistObj];
    },
    isSpecialApportionmentOptions() {
      return [
        {
          value: null,
          caption: '(any)',
        },
        {
          value: false,
          caption: 'No',
        },
        {
          value: true,
          caption: 'Yes',
        },
      ];
    },
    isApportionLikeTaxOptions() {
      return [
        {
          value: null,
          caption: '(any)',
        },
        {
          value: false,
          caption: 'No',
        },
        {
          value: true,
          caption: 'Yes',
        },
      ];
    },
    isVoidOptions() {
      return [
        {
          value: null,
          caption: 'All',
        },
        {
          value: true,
          caption: 'Void',
        },
      ];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapActions('misc/miscTransaction', [
      'loadMiscTransaction',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapActions('shared/source', [
      'loadCmnSource',
    ]),
    ...mapActions('misc/schoolUnit', [
      'loadMiscUnitCode',
    ]),
    ...mapActions('shared/taxDistrict', [
      'loadTaxDistrict',
    ]),
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    handleLoadCRUDList(criteriaArgs = {}) {
      this.$emit('loadCRUDList', criteriaArgs);
    },
    loadLists() {
      this.loadFunds({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'sharedFund',
      });
      this.loadCmnSource({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          isSkipMiscellaneousTransaction: false,
          sortBy: [
            {
              column: 'sourceSubSourceNumber',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'sharedSource',
      });
      this.loadMiscUnitCode({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          sortBy: [
            {
              column: 'unitCode',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'miscUnitCode',
      });
    },
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);
      // delay new call
      this.delayTimerId = setTimeout(async () => {
        const criteriaArgs = { searchMode: 'wildcard' };
        await this.handleLoadCRUDList(criteriaArgs);
      }, 250);
    },
  },
};
</script>
