<template>
  <button
    :api_access_id="forteConfig.apiAccessId"
    :version_number="forteConfig.versionNumber"
    :method="forteConfig.method"
    :location_id="forteConfig.locationId"
    :total_amount="forteConfig.totalAmount"
    :utc_time="forteConfig.utcTime"
    :order_number="forteConfig.tmcLocalTrusts"
    :signature="forteConfig.apiSignature"
    allowed_methods="visa,mast,disc,amex"
    callback = 'forteCb'
    hash_method="sha1"
    save_token="false"
    swipe="true"
    billing_company_name_attr="hide"
  >
    Proceed to Pay
  </button>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import {
  forteApiModes,
} from '../../util/shared/tmc-global';

let self;

const cleanupDuplicateDialog = () => {
  // forte has static class for dialog
  const dialog = document.getElementsByClassName('fco2-iframe-container');
  const dialogCount = (dialog || []).length;
  if (dialogCount > 1) {
    for (let i = 1; i < dialogCount; i += 1) {
      dialog[i].remove();
    }
  }
};

const forteCb = (e) => {
  cleanupDuplicateDialog();
  let response = {};
  try {
    response = JSON.parse(e.data);
  } catch (err) {
    /* do nothing for now */
    // console.error(err);
  }
  switch (response.event) {
    case 'begin':
      // do nothing
      break;
    case 'abort':
      // do nothing
      break;
    case 'success':
      // transaction successful
      self.$emit('forteResponse', `{trace_number: ${response.trace_number} }`);
      alert(`Thanks for your order. the trace number is ${response.trace_number}`);
      break;
    case 'failure':
      // handle failed transaction
      alert(`Sorry, transaction failed. failed reason is  ${response.response_description}`);
      break;
    default:
      // do nothing
      break;
  }
};

const defaultForteApiURL = () => forteApiModes[0].apiURL;

export default {
  name: 'FortePayment',
  data: () => ({
    forteConfig: {},
  }),
  async mounted() {
    self = this;
    window.forteCb = forteCb;
    this.buildForteUtility();
  },
  computed: {
    ...mapState({
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem || {},
    }),
  },
  methods: {
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    ...mapActions([
      'flashWarn',
    ]),
    async buildForteUtility() {
      await this.loadConfig({ requiredScope: 'global' });
      const forteApiURL = this.globalSettingItem.forteApiURL || defaultForteApiURL();
      const fortePaymentScript = document.createElement('script');
      fortePaymentScript.setAttribute('src', forteApiURL);
      document.head.appendChild(fortePaymentScript);
      this.forteConfig = this.$store.state.ForteService.forteConfig || {};
      if (forteApiURL === defaultForteApiURL()) {
        this.flashWarn('Your Forte account is operating in "Sandbox Mode". If you wish to accept live payments, please update your Forte setting.');
      }
    },
  },
};
</script>
