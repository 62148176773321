<template>
  <div>
    <v-container class="grey lighten-5 mb-6">
      <h3>{{toolbarTitle}}</h3>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Payment Type
              </th>
              <th class="text-left"></th>
              <th class="text-left"></th>
              <th class="text-left"></th>
              <th class="text-left">
                <v-btn
                  class="mx-2 tmc-payment-details-plus-icon" color="primary"
                  fab icon x-small
                  @click="addOne"
                  v-if="hideUnhidePaymentAndBatchPaymentDetails.canAddPaymentDetails"
                >
                  <v-icon>
                    {{icons.mdiPlus}}
                  </v-icon>
                </v-btn>
                <FortePayment
                  v-if="showFortePaymentBtn"
                  @forteResponse="forteResponse"
                />
              </th>
              <th class="text-left"
              v-if="hideUnhidePaymentAndBatchPaymentDetails.showBatchPaymentUtility">
                <v-btn
                  class="mx-2 edit-batch-payment-details-btn"
                  color="primary"
                  small
                  @click="emitEditBatchPaymntItem"
                >
                  Edit Batch Payment
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in editedItem"
              :key="index"
            >
              <td>
                <v-select
                  v-model="item.paymentType"
                  label="Payment Type"
                  :items="cmnEnums.cmnPaymentTypes"
                  :rules="reqdSelectValidation"
                  :readonly="isReadOnly"
                  item-text="description"
                  item-value="name"
                  class="tmc-payment-details-paymentType"
                  @change="somethingChanged(); onChangePaymentType(index);
                  onChangeCcTypeOrAmount(item);"
                  dense
                  single-line
                ></v-select>
              </td>
              <td v-if="item.paymentType === 'CASH'">
                <v-text-field
                  dense
                  single-line
                  hint="Cash Received"
                  persistent-hint
                  v-model="item.cashReceived"
                  :readonly="isReadOnly"
                  @blur="somethingChanged"
                  class="tmc-payment-details-cashReceived"
                ></v-text-field>
              </td>
              <td v-if="item.paymentType === 'CHECK'">
                <v-text-field
                  dense
                  single-line
                  hint="Check Number"
                  persistent-hint
                  :readonly="isReadOnly"
                  v-model="item.checkNumber"
                  class="tmc-payment-details-checkNumber"
                  @blur="somethingChanged"
                ></v-text-field>
              </td>
              <td v-if="item.paymentType === 'EFT'">
                <v-select
                  dense
                  single-line
                  hint="EFT Type"
                  persistent-hint
                  v-model="item.eftType"
                  v-if="item.paymentType === 'EFT'"
                  item-text="description"
                  item-value="name"
                  class="tmc-payment-details-eftType"
                  :items="cmnEnums.cmnEFTTypes"
                  :readonly="isReadOnly"
                  @blur="somethingChanged"
                ></v-select>
              </td>
              <td v-if="item.paymentType === 'CREDIT'">
                <v-select
                  v-model="item.creditType"
                  hint="CC Type"
                  persistent-hint
                  item-text="description"
                  item-value="name"
                  class="tmc-payment-details-creditType"
                  dense
                  single-line
                  :items="cmnEnums.cmnCardTypes"
                  :readonly="isReadOnly"
                  @blur="somethingChanged"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  dense
                  single-line
                  :hint="settingAmountPlaceHolder(item)"
                  :readonly="isReadOnly"
                  persistent-hint
                  v-model.number="item.amount"
                  class="tmc-payment-details-amount"
                  @blur="somethingChanged(); calculateCashReceived(index);
                  onChangeCcTypeOrAmount(item);"
                  @keydown.enter="somethingChanged"
                  @keydown="shortcutHandler($event, item.paymentType, 'amount', index);"
                ></v-text-field>
              </td>
              <td v-if="item.paymentType === 'CASH'">
                <v-text-field
                  dense
                  single-line
                  hint="Change"
                  persistent-hint
                  :readonly="isReadOnly"
                  v-model="item.cashChange"
                  class="tmc-payment-details-change"
                  @blur="somethingChanged"
                  @keydown.enter="somethingChanged"
                  @keydown="shortcutHandler($event, item.paymentType, 'change', index);"
                ></v-text-field>
              </td>
              <td v-if="item.paymentType === 'CHECK'">
                <v-text-field
                  dense
                  single-line
                  hint="Paid By"
                  persistent-hint
                  v-model="item.checkPaidBy"
                  class="tmc-payment-details-paidBy"
                  :readonly="isReadOnly"
                  @blur="somethingChanged"
                  @keydown.enter="somethingChanged"
                  @keydown="shortcutHandler($event, item.paymentType, 'paidBy', index);"
                ></v-text-field>
              </td>
              <td v-if="item.paymentType === 'EFT' || item.paymentType === 'CREDIT'"></td>
              <td v-if="isTotal">
                <v-icon
                  small class="mr-2 tmc-payment-details-delete-icon"
                  tabindex="-1"
                  @click="removeOne(item)"
                >
                  {{icons.mdiDelete}}
                </v-icon>
              </td>
            </tr>
            <tr v-if="isTotal"
            class="blue--text tmc-payemnt-details-total-amount">
              <td colspan="2" id="total-trans-amount"
              v-if="((editedItem || []).length) !== 0">
                Total : ${{ amountFormat(amountRemaining) }}
              </td>
          </tr>
        </tbody>
      </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import { mapActions, mapMutations } from 'vuex';
import FortePayment from '../FortePayment.vue';

import {
  amountFormat,
  amountToNumber,
  isTabOrPlus,
  checkStringEmptiness,
} from '../../../util/shared/vue-global';

const defaultItem = {
  paymentType: '',
  amount: 0,
};
const isCreditItem = (item) => (item.paymentType === 'CREDIT');

export default {
  name: 'PaymentDetailsComponent',
  components: {
    FortePayment,
  },
  created() {},
  props: {
    baseCrudKey: {
      type: String,
      default: 'trans-dialog',
    },
    value: {
      type: Array,
      default: () => ([]),
    },
    whomString: {
      type: String,
      default: '',
    },
    cmnEnums: {
      type: Object,
      default: () => ({}),
    },
    paymentTypeFlags: {
      type: Object,
      default: () => ({}),
    },
    defaultPaymentType: {
      type: Object,
      default: () => ({}),
    },
    defaultForteConfig: {
      type: Object,
      default: () => ({}),
    },
    amount: [Number, String],
    isDialogBoxVisible: {
      type: Boolean,
      default: false,
    },
    isTotal: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    toolbarTitle: {
      type: String,
      default: 'Items',
    },
    transactionId: {
      type: String,
      default: null,
    },
    paymentDetailsFlags: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    renderForteComponentFlag: false,
  }),
  watch: {
    isDialogBoxVisible() {
      if (this.isDialogBoxVisible) {
        this.onLoadDialogBox();
      } else {
        this.renderForteComponentFlag = false;
      }
    },
  },
  computed: {
    editedItem: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    amountRemaining() {
      let totalRemainingPaymentAmount = amountToNumber(this.amount);
      for (let i = 0; i < (this.editedItem || []).length; i += 1) {
        totalRemainingPaymentAmount -= amountToNumber(this.editedItem[i].amount);
      }
      this.$emit('totalRemainingPaymentAmount', totalRemainingPaymentAmount);
      return totalRemainingPaymentAmount;
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    totalCreditAmount() {
      let totalCrediAmtSum = 0.00;
      (this.editedItem || []).forEach((item) => {
        if (item.paymentType === 'CREDIT') {
          totalCrediAmtSum += amountToNumber(item.amount);
        }
      });
      return totalCrediAmtSum;
    },
    showFortePaymentBtn() {
      return this.renderForteComponentFlag && this.defaultForteConfig.isRequiredForte;
    },
    isTransactionId() {
      return !checkStringEmptiness(this.transactionId);
    },
    isAmountRemainingZero() {
      return amountToNumber(this.amountRemaining) === 0;
    },
    hideUnhidePaymentAndBatchPaymentDetails() {
      const flags = {
        canAddPaymentDetails: false,
        showBatchPaymentUtility: false,
      };
      if (Object.keys(this.paymentDetailsFlags).length > 0) {
        if (this.paymentDetailsFlags.isCallForBatchPayment && this.isTransactionId) {
          flags.showBatchPaymentUtility = true;
        } else if (this.paymentDetailsFlags.isCallForNormalPayment && !this.isTransactionId) {
          flags.canAddPaymentDetails = !this.isAmountRemainingZero;
        }
      } else {
        flags.canAddPaymentDetails = !this.isAmountRemainingZero;
      }
      return flags;
    },
  },
  mounted() {
    this.setConfigData({});
  },
  methods: {
    ...mapActions('ForteService', [
      'fetchForteConfig',
    ]),
    ...mapMutations('ForteService', [
      'setConfigData',
    ]),
    amountFormat,
    amountToNumber,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    addOne() {
      this.$emit('input', [
        ...this.editedItem,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.editedItem.filter((candidate) => item !== candidate),
      ]);
    },
    emitEditBatchPaymntItem() {
      this.$emit('emitEditBatchPaymntItem');
    },
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    settingAmountPlaceHolder(item) {
      if (item && item.paymentType) {
        if (item.paymentType === 'CASH') {
          return 'Cash Required';
        }
        if (item.paymentType === 'CREDIT' || item.paymentType === 'EFT') {
          return 'Amount';
        }
        if (item.paymentType === 'CHECK') {
          return 'Check Amount';
        }
      }
      return '';
    },
    autoFillCheckPaidBy(index) {
      if (this.editedItem[index].paymentType && this.editedItem[index].paymentType === 'CHECK') {
        this.editedItem[index].checkPaidBy = this.whomString || '';
      }
      return this.editedItem;
    },
    calculateCashReceived(index) {
      if (this.editedItem[index].paymentType === 'CASH' && this.editedItem[index].amount && this.editedItem[index].cashReceived) {
        this.editedItem[index].cashReceived = amountFormat(
          amountToNumber(this.editedItem[index].cashReceived),
        );
        this.editedItem[index].cashChange = amountFormat(
          amountToNumber(this.editedItem[index].cashReceived)
          - amountToNumber(this.editedItem[index].amount),
        );
      }
      return this.editedItem;
    },
    shortcutHandler(e, paymentType, fieldName, index) {
      let totalAmount = 0;
      if (isTabOrPlus(e) && (this.isPaymentTypeSelected(paymentType, fieldName))) {
        if ((index + 1) === (this.editedItem || []).length) {
          for (let i = 0; i < (this.editedItem || []).length; i += 1) {
            totalAmount += amountToNumber(this.editedItem[i].amount);
            this.somethingChanged();
            const amountDifference = amountToNumber(this.amount) - totalAmount;
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/sign
            if ((Math.sign(amountToNumber(this.amount)) === 1) && (amountDifference > 0)) {
              this.addOne();
            } else if ((Math.sign(amountToNumber(this.amount)) === -1) && (amountDifference < 0)) {
              this.addOne();
            }
          }
        }
      }
    },
    isPaymentTypeSelected(paymentType, fieldName) {
      return ((paymentType === 'CHECK' && fieldName === 'paidBy')
      || (paymentType === 'CASH' && fieldName === 'change')
      || (paymentType === 'EFT' && fieldName === 'amount')
      || (paymentType === 'CREDIT' && fieldName === 'amount'));
    },
    onChangePaymentType(index) {
      this.autoFillCheckPaidBy(index);
      this.assignDefaultPaymentType(index);
    },
    assignDefaultPaymentType(index) {
      if (this.paymentTypeFlags && this.paymentTypeFlags.eftPaymentType && this.editedItem[index].paymentType === 'EFT') {
        this.editedItem[index].eftType = (this.defaultPaymentType.eftPaymentType).toUpperCase();
      }
      if (this.paymentTypeFlags && this.paymentTypeFlags.ccPaymentType && this.editedItem[index].paymentType === 'CREDIT') {
        this.editedItem[index].creditType = (this.defaultPaymentType.ccPaymentType).toUpperCase();
      }
    },
    forteResponse(response) {
      this.$emit('forteResponse', response);
    },
    onLoadDialogBox() {
      const isCreditItemAvailable = (this.editedItem || []).find((item) => isCreditItem(item));
      if (isCreditItemAvailable) {
        this.fetchForteConfiguration();
      }
    },
    onChangeCcTypeOrAmount(item) {
      if (isCreditItem(item)) {
        this.fetchForteConfiguration();
      }
    },
    async fetchForteConfiguration() {
      if (this.defaultForteConfig.isRequiredForte) {
        // Remove forte-component from the DOM
        this.renderForteComponentFlag = false;
        // call forte event
        await this.fetchForteConfig({
          scope: this.defaultForteConfig.configScope,
          forteAmount: this.totalCreditAmount.toString(),
        });
        this.$nextTick(() => {
          // Add the forte-component back in
          this.renderForteComponentFlag = true;
        });
      }
    },
  },
};
</script>

<style lang="sass">
  .eft-rec-amount input
    text-align: right
</style>
