<template>
  <div class="AuditDialog">
    <v-dialog
      v-model="auditDialog"
      width="45vw"
      max-height="80vh">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          v-on="on"
          @click="setCriteriaAndLoad"
        >
          {{activatorCaption}}
        </v-btn>
      </template>
      <v-card>
        <v-container>
          <v-row>
            <v-spacer />
            <v-col align="right">
              <v-btn
                icon
                @click="auditDialog = false"
              >
                <v-icon>{{icons.mdiClose}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1" />
            <v-col cols="10">
              <CRUDList
                :canAdd="false"
                :canEdit="true"
                :canSave="false"
                :canDelete="false"
                :headers="headers"
                @loadCRUDList="handleLoadAudits"
                :defaultSortBy="defaultSortBy"
                :baseCrudKey="baseCrudKey"
                toolbarTitle="Audit Items"
              >
                <template v-slot:[`editedItem`]="{}">
                  <AuditEditedItem :baseCrudKey="baseCrudKey" />
                </template>
              </CRUDList>
            </v-col>
            <v-col cols="1" />
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  mdiClose,
} from '@mdi/js';

import CRUDList from '../base/crud/CRUDList.vue';
import AuditEditedItem from './AuditEditedItem.vue';

import { compareLogs } from '../../../store/audit/index';

const flagInterestingChanges = (items) => {
  const amountChangeStyle = ['orange', 'accent-1'];
  return compareLogs(items, amountChangeStyle);
};

const fields = {
  _id: '',
  program: '',
  type: '',
  subtype: '',
  username: '',
  when: new Date(),
  remarks: '',
};

const initdata = () => ({
  icons: {
    mdiClose,
  },
  headers: [
    { text: 'Program', value: 'program' },
    { text: 'Type', value: 'type' },
    { text: 'SubType', value: 'subtype' },
    { text: 'User', value: 'username' },
    { text: 'Date/Time', value: 'when', formatter: 'localDateTime' },
  ],
  auditDialog: false,
});

export default {
  name: 'AuditDialog',
  components: {
    CRUDList,
    AuditEditedItem,
  },
  props: {
    activatorCaption: {
      type: String,
      default: 'Audit History',
    },
    identifier: {
      type: String,
      default: undefined,
    },
    baseCrudKey: {
      type: String,
      default: 'audit-dialog',
    },
  },
  data: () => initdata(),
  created() {
    // reset criteria while merge flag is true
    this.setCriteria([this.baseCrudKey, {}]);
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'items',
    ]),
    defaultSortBy() {
      return [
        {
          column: 'when',
          direction: 'desc',
        },
      ];
    },
  },
  methods: {
    ...mapActions('audit', [
      'loadAudits',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItems',
    ]),
    async setCriteriaAndLoad() {
      // set criteria
      const { identifier } = this;
      const sortBy = this.defaultSortBy;
      this.setCriteria([this.baseCrudKey, { identifier, sortBy }, true]);
      await this.handleLoadAudits();
    },
    async handleLoadAudits() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      await this.loadAudits({
        criteria,
        fields,
        baseCrudKey,
        postProcessAudits: flagInterestingChanges,
      });
    },
  },
};
</script>
